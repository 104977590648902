<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card outlined class="primary--border">
          <v-card-title>
            Optional Groups
            <v-spacer />
          </v-card-title>
          <v-card-title class="pb-0">
            <v-flex xs4>
              <v-select
                :loading="loadingGrade"
                :disabled="grades.length < 1"
                @change="getSections(), getSubjects(), getOptionalList()"
                :items="grades"
                outlined
                dense
                class="pa-0"
                label="Select grade"
                v-model="grade"
              />
            </v-flex>
            <v-flex xs4>
              <v-select
                :loading="loadingSection"
                :disabled="sections.length < 1"
                :items="sections"
                @change="getOptionalList()"
                class="pa-0"
                outlined
                dense
                label="Select section"
                v-model="section"
              />
            </v-flex>
          </v-card-title>

          <v-container class="subject_list_group">
            <h4
              v-if="selectedGrades && subjectList.length > 0"
              style="color:#777;font-weight: normal"
            >
              Subject List for
              <strong style="color:#444"
                >{{ selectedGrades }}
                <span v-if="selectedSection"
                  >({{ selectedSection }})</span
                ></strong
              >&nbsp;
              <small v-if="hasOptionalSubjects"
                >(Click to select the highlighted optional subject.)
              </small>
            </h4>
            <span v-for="(subject, key) in subjectList" :key="key">
              <v-chip class="mr-2" v-if="subject.sub_type === 'comp'">
                {{ subject.name }}
              </v-chip>
              <v-tooltip v-else-if="subject.sub_type === 'opt'" bottom>
                <template v-slot:activator="{ on }">
                  <v-chip
                    v-on="on"
                    class="mr-2 mt-2"
                    @click="selectOptSubject(subject)"
                    color="primary"
                    text-color="white"
                  >
                    <v-avatar v-if="selectedOptSubjects.indexOf(subject) > -1">
                      <v-icon>check_circle</v-icon>
                    </v-avatar>
                    {{ subject.name }} &nbsp; (<strong
                      >{{
                        getTotalNumberOfStudentEachOptSubject(subject.id)
                      }})</strong
                    > </v-chip
                  >&nbsp;
                </template>
                <span>Click to filter {{ subject.name }} students</span>
              </v-tooltip>
            </span>

            <span v-if="subjectList.length">
              <br />
              <br />
            </span>
            <alert-message
              type="error"
              v-if="subjectList.length > 0 && !hasOptionalSubjects"
              title="Optional Subject Alert"
            >
              <template v-slot:message
                ><strong>Warning!!</strong> No optional subjects has been
                specified for this grade <strong>({{ selectedGrades }})</strong>
                yet. Please specify some optional subjects and try again.
              </template>
            </alert-message>

            <v-data-table
              :loading="loading"
              :headers="headers"
              :items="allStudent"
              :options.sync="pagination"
              hide-default-footer
              :footer-props="footerProps"
              :server-items-length="allStudent.length"
              style="margin-top: 15px"
            >
              <template v-slot:item="{ index, item }">
                <tr :key="index">
                  <td class="text-xs-left">
                    {{ item.roll }}
                  </td>
                  <td>
                    {{ item.name }}
                  </td>

                  <td class="text-right">
                    <span v-for="(subject, key) in subjectList" :key="key">
                      <v-chip
                        class="mr-2"
                        @click="addOrRemoveOptionalSubject(item, subject)"
                        v-if="subject.sub_type === 'opt'"
                        :color="
                          item.subjects.indexOf(subject.id) > -1
                            ? 'primary'
                            : ''
                        "
                        :text-color="
                          item.subjects.indexOf(subject.id) > -1 ? 'white' : ''
                        "
                      >
                        <v-avatar v-if="item.subjects.indexOf(subject.id) > -1">
                          <v-icon>check_circle</v-icon>
                        </v-avatar>
                        {{ subject.name }} </v-chip
                      >&nbsp;
                    </span>
                  </td>
                </tr>
              </template>
            </v-data-table>

            <br />
            <br />

            <edit-button
              :loading="saving"
              editText="Update"
              :disabled="
                !isChanged || saving || !allStudentHaveOptionalSubjects
              "
              label="Update student's optional subjects"
              v-if="allStudent.length > 0 && isCurrentBatch"
              @agree="updateOptionalList"
              permission="student-update"
              :large="true"
              :block="true"
            >
            </edit-button>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import { getPropertyFromArrayObject } from "@/library/helpers";
import Mixins from "@/library/Mixins";

export default {
  mixins: [Mixins],

  data: () => ({
    isChanged: false,
    saving: false,
    loading: false,
    form: new Form({}, "/api/grades"),
    grades: [],
    grade: "",
    section: "",
    sections: [],
    loadingGrade: false,
    loadingSection: false,
    subjectList: [],
    allStudent: [],
    selectedOptSubjects: [],
    headers: [
      {
        text: "Roll",
        value: "roll",
        sortable: false,
        align: "left",
        width: 20,
      },
      {
        text: "Name",
        value: "name",
        align: "left",
        sortable: false,
        width: 200,
      },
      { text: "", value: "", sortable: false, align: "right" },
    ],
  }),

  computed: {
    ...mapState(["batch"]),
    selectedGrades() {
      let $this = this;
      let grade = "";
      this.grades.map(function(data) {
        if ($this.grade === data.value) {
          grade = data.text;
        }
      });
      return grade;
    },
    selectedSection() {
      let $this = this;
      let section = "";
      this.sections.map(function(data) {
        if ($this.section === data.value) {
          section = data.text;
        }
      });
      return section;
    },

    hasOptionalSubjects() {
      let flag = false;
      this.subjectList.map(function(sub) {
        if (sub.sub_type === "opt") {
          flag = true;
        }
      });
      return flag;
    },
    allStudentHaveOptionalSubjects() {
      let output = true;
      try {
        this.allStudent.map((item) => {
          if (item.subjects.length < 1) {
            output = false;
            throw Error("Option subject");
          }
        });
      } catch (e) {}
      return output;
    },

    isCurrentBatch() {
      return this.batch && this.batch.is_current_batch === true;
    },
  },
  mounted() {
    this.getGrades();
  },
  watch: {},
  methods: {
    addOrRemoveOptionalSubject(item, subject) {
      this.isChanged = true;
      if (item.subjects.indexOf(subject.id) > -1) {
        item.subjects.splice(item.subjects.indexOf(subject.id), 1);
      } else {
        item.subjects.push(subject.id);
      }
    },
    getTotalNumberOfStudentEachOptSubject(subjectId) {
      let output = 0;
      this.allStudent.map((item) => {
        if (item.subjects.indexOf(subjectId) > -1) {
          output++;
        }
      });
      return output;
    },
    getGrades() {
      this.sections = [];
      this.section = "";
      this.loadingGrade = true;
      this.$rest
        .get(
          "/api/grade-optional-subject?itemsPerPage=100&sortBy=rank&descending=false&slim=true"
        )
        .then(({ data }) => {
          this.grades = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        })
        .finally((err) => {
          this.loadingGrade = false;
        });
    },
    getSections() {
      this.section = "";
      this.sections = [];
      this.loadingSection = true;
      this.$rest
        .get("/api/section?batch=" + this.batchId + "&grade=" + this.grade)
        .then(({ data }) => {
          this.sections = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        })
        .finally(() => {
          this.loadingSection = false;
        });
    },

    getOptionalList() {
      let subjectId = getPropertyFromArrayObject(
        "id",
        this.selectedOptSubjects
      );
      let subject = "";
      if (subjectId.length) subject = subjectId.join(",");
      this.loading = true;
      this.$rest
        .get(
          "/api/subject-opt-group?itemsPerPage=25&grade=" +
            this.grade +
            "&section=" +
            this.section +
            "&subject=" +
            subject
        )
        .then(({ data }) => {
          this.allStudent = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getSubjects() {
      this.selectedOptSubjects = [];
      this.subjectList = [];
      this.$rest
        .get("/api/subject?itemsPerPage=25&sortBy=sub_type&grade=" + this.grade)
        .then(({ data }) => {
          this.subjectList = data.data.filter((data) => {
            if (data.exam === true) {
              return data;
            }
          });
        });
    },
    selectOptSubject(subject) {
      if (this.selectedOptSubjects.indexOf(subject) > -1) {
        this.selectedOptSubjects.splice(
          this.selectedOptSubjects.indexOf(subject),
          1
        );
      } else {
        this.selectedOptSubjects.push(subject);
      }
      this.getOptionalList();
    },
    updateOptionalList() {
      let subjectId = getPropertyFromArrayObject(
        "id",
        this.selectedOptSubjects
      );
      let subject = "";
      if (subjectId.length) subject = subjectId.join(",");
      this.saving = true;
      this.$rest
        .post("api/subject-opt-group", {
          students: this.allStudent,
          subjects: subject,
        })
        .then(({ data }) => {
          this.$events.fire("notification", { message: data.message });
          this.getOptionalList();
        })
        .finally(() => {
          this.saving = false;
        });
    },
  },
};
</script>
<style lang="scss">
.subject_list_group {
  .v-chip,
  .v-chip .v-chip__content {
    border-radius: 2px;
  }
}

.optional-subject {
  .v-chip {
    margin-left: 0;
  }

  .v-chip--small {
    height: 20px !important;
    padding: 0 !important;
  }

  .v-chip--removable .v-chip__content {
    padding: 7px 0px 7px 5px !important;
  }

  .v-chip__content {
    font-size: 10px;
  }
}
</style>
